import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import MyProvider from "./myContext/MyProvider";

import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ErrorBoundary";
const Chat = React.lazy(() => import("./components/Chat"));

// Define the global initialization function
window.initializeChatWidget = () => {
  console.log("DOMContentLoaded event fired");
  const rootDiv = document.createElement("div");
  rootDiv.id = "chat_app";
  document.body.appendChild(rootDiv);

  rootDiv.style.position = "fixed";
  rootDiv.style.bottom = "20px";
  rootDiv.style.left = "20px";
  rootDiv.style.zIndex = "45000";
  rootDiv.classList.add("notranslate");
  const root = ReactDOM.createRoot(rootDiv);
  const urlParams = new URLSearchParams(window?.location?.search);
  const autoOpenChat = urlParams.get('openChat') === 'true' ? true : false ;

  root.render(
    <MyProvider>
      <React.StrictMode>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Chat autoOpenChat={autoOpenChat} />
          </Suspense>
        </ErrorBoundary>
      </React.StrictMode>
    </MyProvider>
  );
};

// Optional: Automatically call initializeChatWidget if needed
// This can be commented out or removed if you plan to call it manually from the host website
window.initializeChatWidget();

reportWebVitals();

import React, { useState } from "react";
import MyContext from "./MyContext";
const MyProvider = ({ children }) => {
  const [language, setLanguage] = useState("");

  return (
    <MyContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
